
$(function () {
    $('#inputGameId').keypress(function (e) {
        if (e.which == 13) {
            getGameId();
        }
    })
})

$('#getGameId').on('click', function (event) {
    getGameId();
});

function getGameId() {
    let matchId = $('#matchId').val();
    if (matchId != "") {
        connect(matchId);
    }
}

function connect(matchId) {
    socket.emit('askToJoin', matchId, (response) => {
        console.log(response);
        drawScore(response.score);
        changeServe(response.nextServe);
    });
}

socket.on("point", (arg) => {
    drawScore(arg.score);
    changeServe(arg.nextServe);
});

function changeServe(nextServe) {
    $('#scoreP1 .player').removeClass("player--serve");
    $('#scoreP2 .player').removeClass("player--serve");
    $('#scoreP'+nextServe+' .player').addClass("player--serve");
}

function drawScore(score) {
    let scoreP0 = $('#scoreP0');
    let scoreP1 = $('#scoreP1');

    let points = score.points.split("-", 2);
    $('#scoreP0 .game__score').html(points[0]);
    $('#scoreP1 .game__score').html(points[1]);

    let games = score.games.split("-", 2);

    $('#scoreP0 .set__score--actual').html(games[0]);
    $('#scoreP1 .set__score--actual').html(games[1]);

    let setsArr = score.sets.split("-", 2);
    let noSets = parseInt(setsArr[0]) + parseInt(setsArr[1]);
    $('#scoreP0 .set__score').not('.set__score--actual').remove();
    $('#scoreP1 .set__score').not('.set__score--actual').remove();
    if (noSets > 0) {
        for (let i = 0; i < noSets; i++) {
            let set = score.components.sets[i];
            $('#scoreP0 .set').append(`<div class="set__score">${set.games[0]}</div>`);
            $('#scoreP1 .set').append(`<div class="set__score">${set.games[1]}</div>`);
        }
    }

}